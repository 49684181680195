<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Employee')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- Field: Login User Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="User Name"
                rules="required"
              >
                <b-form-group
                  :label="$t('User') + ' ' + $t('Name')"
                  label-for="username"
                >
                  <b-form-input
                    id="username"
                    v-model="employeeData.username"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    @input="removeSpaces"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Password -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Password"
                rules="min:8"
              >
                <b-form-group
                  :label="$t('Password')"
                  label-for="password"
                >
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="employeeData.password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="getValidationState(validationContext)"
                      name="password"
                      placeholder=""
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>

            <!-- Field: Role -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- User Role -->
              <validation-provider
                #default="validationContext"
                name="role"
                rules="required"
              >
                <b-form-group
                  :label="$t('Role')"
                  label-for="role"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.role_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="role"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Login Employee Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Employee') + ' ' + $t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="employeeData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  :label="$t('Email')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="employeeData.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Phone -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required|alpha-num"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="employeeData.phone"
                    class="form-control-merge"
                    :state="getValidationState(validationContext)"
                    name="phone"
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>

            <!-- Field: Comment -->
            <b-col
              md="4"
            >
              <b-form-group
                :label="$t('Comment')"
                label-for="comment"
              >
                <b-form-input
                  id="comment"
                  v-model="employeeData.comment"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- Status -->
              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Branch -->
            <b-col
              v-if="$can('manage', 'all')"
              cols="12"
              md="4"
            >
              <!-- Branch -->
              <validation-provider
                #default="validationContext"
                name="branch"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.branch_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="branch_id"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="gradient-secondary"
              pill
              @click="cancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import storeModule from '../../../common/storeModule'
import employeeStoreModule from '../employeeStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {

  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    removeSpaces() {
      // Remove spaces from the input value
      this.employeeData.username = this.employeeData.username.replace(/\s/g, '')
    },
    cancel() {
      this.$router.push({ name: 'employee-list' })
    },
  },
  setup(props, context) {
    const USER_APP_STORE_MODULE_NAME = ''

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const branchesArray = []
    const branchOptions = ref([])

    store.dispatch('common/fetchBranches')
      .then(response => {
        const { branches } = response.data.data
        for (let i = 0; i < branches.length; i += 1) {
          branchesArray.push({ label: branches[i].name, value: branches[i].id })
        }
        branchOptions.value = branchesArray
      })

    const can = context.root.$can
    const roleOptions = can('manage', 'all') ? [
      { label: 'Admin', value: 1 },
      { label: 'Manager', value: 2 },
      { label: 'Sale', value: 3 },
      { label: 'Free', value: 4 },
    ] : [
      { label: 'Manager', value: 2 },
      { label: 'Sale', value: 3 },
    ]

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ]

    const blankEmployeeData = {
      username: '',
      password: '',
      role_id: '',
      name: '',
      email: '',
      phone: '',
      comment: '',
      branch_id: null,
      status: 1,
    }
    const router = context.root.$router
    const employeeData = ref(JSON.parse(JSON.stringify(blankEmployeeData)))
    const employeeId = router.currentRoute.params.id
    store.dispatch('/fetchOne', { id: employeeId })
      .then(response => {
        const responseEmployeeData = {
          id: response.data.data.id,
          username: response.data.data.user.name,
          role_id: response.data.data.user.role.id,
          name: response.data.data.name,
          email: response.data.data.user.email,
          phone: response.data.data.phone,
          comment: response.data.data.comment,
          branch_id: response.data.data.branch_id,
          status: response.data.data.user.status,
        }
        employeeData.value = responseEmployeeData
      })
      .catch(error => {
        if (error.response.status === 404) {
          employeeData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(employeeData.value))
      store.dispatch('/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'employee-list' })
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      employeeData,
      onSubmit,
      branchOptions,
      statusOptions,
      roleOptions,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
